import { Component, ElementRef, Input } from '@angular/core';
import { SubcontentComponent } from '../base/subcontent.component';
import { LinkService } from '../../services/link.service';
import { ConverterService } from '../../services/converter.service';
import { DateService } from '../../services/date.service';
import { SubcontentService } from '../../services/subcontent.service';
import { MappingSubcontent, MappingSubcontentFile } from '../../types/MappingTypes';

@Component({
  selector: 'app-subcontent--file',
  templateUrl: '../base/subcontent.component.html',
  styleUrls: ['./file.subcontent.component.scss']
})
export class FileSubcontentComponent extends SubcontentComponent{

  @Input() override  representedMappingElement! : MappingSubcontentFile;

  constructor(
    protected override linkService : LinkService,
    protected override converterService : ConverterService,
    protected override dateService : DateService,
    protected override componentRef : ElementRef,
    protected override subcontentService : SubcontentService
  ){
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService
    );
  }

  override init() {
    switch(this.representedMappingElement.typ){
    case 'file':
    case 'virtual_file':
      this.handleFile();
      break;
    case 'all_files':
      this.handleAllFiles('k');
      break;
    case 'all_images':
      this.handleAllFiles('m');
      break;
    default:
      console.error('Created FileSubcontentComponent but is not of typ file, virtual_file, all_files or all_images!');
      super.init();
    }
  }

  /*
    idea : iterate over all fields and if they contain a file then create a new subcontent component that handles the file
  */
  handleAllFiles(field : 'm' | 'k') {
    const entries = Object.entries(this.exampleElement);
    let length = 0;
    const mappingElements : MappingSubcontent[] = [];
    const exampleElements : string[] = [];
    let mappingChildInformation;
    if(this.representedMappingElement?.subcontent && !this.representedMappingElement?.usefunction){
      mappingChildInformation = this.representedMappingElement?.subcontent[0] as MappingSubcontent;
    } else if (this.representedMappingElement.usefunction){
      console.error('Usefunction handling not implemented.');
      return;
    }
    
    for(const [k,v] of entries){
      if((field === 'm' && k.includes('_sourcename')) || (field === 'k' && k.includes('_attachment'))){
        mappingElements.push({
          typ : 'file',
          epart : k,
          epartpre : mappingChildInformation?.epartpre,
          epartpost : mappingChildInformation?.epartpost,
          eclass : mappingChildInformation?.eclass
        } as MappingSubcontent);
        exampleElements.push(v as string);
        length++;
      }
    }
    const wrapper = this.handleBoxing(length);
    mappingElements.forEach((mappingElement, index) => {
      this.createSubComponent({
        representedMappingElement : mappingElement,
        id : this.id+' '+index,
        exampleElement : exampleElements[index],
        wrapper : wrapper,
        childCount : length,
        isLastBlockElement : index == length-1,
        kTitles : this.kTitles,
        mTitles : this.mTitles
      });
    });    
  }

  handleFile() {
    let title: string;
    let filename : string;
    if(this.representedMappingElement.fudfeldtyp === 'kf' || this.representedMappingElement.fudfeldtyp === 'mf'){
      filename = this.exampleElement.filename;
      title = this.exampleElement.sourcename;
    } else {
      filename = this.exampleElement;
      title = this.getTitle();
    }
    
    if(this.representedMappingElement.eclass?.includes('file-image') // standard mapping
      || this.representedMappingElement.display === 'image') // in links the display option
    { // show file as image (image in img tag, file in iframe)
      if(this.representedMappingElement.epart.includes('_sourcename') || this.representedMappingElement.fudfeldtyp === 'mf'){
        this.subcontent.nativeElement.classList.add('link-content-span');
        const image = this.converterService.createHTMLElement(this.subcontent.nativeElement,'img','');
        const eclass : string[] | undefined = this.representedMappingElement.eclass;        
        if(eclass?.includes('image-original')){
          image.setAttribute('src', 'assets/vmimg/'+filename);
        } else if (eclass?.includes('image-thb')){
          image.setAttribute('src', 'assets/vmimg/thb/thb_'+filename);
        } else { // image-mid or nothing
          image.setAttribute('src', 'assets/vmimg/mid/mid_'+filename);
        }
        image.setAttribute('alt', title);
      } else {
        this.subcontent.nativeElement.classList.add('link-content-span');
        const iframe = this.converterService.createHTMLElement(this.subcontent.nativeElement,'iframe','') as HTMLIFrameElement;
        iframe.setAttribute('src', 'assets/vmimg/anhang/'+filename);
        iframe.setAttribute('alt', title);
      }
    } else { // show file as link
      this.outerContainer.clear();
      this.outerContainer.createEmbeddedView(this.outerLink);
      setTimeout(()=>{
        const anchor = this.content as ElementRef<HTMLAnchorElement>;
        anchor.nativeElement.setAttribute('href', 'assets/vmimg/anhang/'+filename);
        this.subcontent.nativeElement.innerHTML = title;
        this.subcontent.nativeElement.classList.add('link-content-span');
      });
    }
    // timeout because change to anchor tag needs it
    setTimeout(()=>{
      const wrapper = this.handleBoxing(0);
      this.styleSpan(this.subcontent.nativeElement, wrapper);
    });
  }

  // get the title of m or k fields
  private getTitle() {
    const field = this.representedMappingElement.epart as string;
    let attNr: number = 0;
    let isKelseM = true;
    if (field.includes('_attachment')) {
      const index = field.indexOf('_attachment') + '_attachment'.length;
      attNr = Number.parseInt(field.substring(index));
    } else if (field.includes('_sourcename')) {
      isKelseM = false;
      const index = field.indexOf('_sourcename') + '_sourcename'.length;
      attNr = Number.parseInt(field.substring(index));
    }
    let str: string = '';
    if (this.exampleElement != undefined) {
      if (isKelseM) {
        if (this.kTitles) {
          str += this.kTitles[attNr];
        }
      } else {
        if (this.mTitles) {
          str += this.mTitles[attNr];
        }
      }
      if (str.length <= 0) { // default : if no title is given just display the file name as title
        str += this.exampleElement;
      }
    }
    return str;
  }
}