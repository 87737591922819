<mat-progress-bar class="position-absolute" *ngIf="queryStateService.isLoading$ | async"
  mode="indeterminate"></mat-progress-bar>

<mat-paginator [length]="totalDocuments" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
  (page)="onPageChange($event)" aria-label="Select page">
</mat-paginator>

<div class="container-fluid scroll" *ngIf="!(queryStateService.isLoading$ | async)">
  <div class="row">
    <div class="col mb-5 pb-3" @listFadeIn>
      <ng-container *ngIf="searchResults">
        <ng-container *ngFor="let document of searchResults?.hits?.hits">
          <div class="container-fluid">
            <div class="d-flex flex-row align-items-start mobile-column p-3 item">
              <!-- Erste Spalte -->
              <div class="flex-grow-1 d-flex align-items-start">
                <!-- Inhalt der zweiten Spalte -->
                <div class="content p-4">
                  {{ document["_source"]["ID"] }}
                  <div class="mt-4">
                    <a href="/#/document/{{ document['_id'] }}" (click)="toDocument(document['_id'])"
                      class="text-sm font-semibold leading-6 text-primary-600 hover:text-primary-500">View document
                      <span aria-hidden="true">&rarr;</span></a>
                  </div>
                </div>
                <h2>
                  <ng-container *ngIf="document['_source']['5_dcterms_title']">
                    <!-- <em>Titel:</em> -->
                    <ng-container *ngIf="document['_source']['url'] != ''; else noUrl">
                      <a href="{{ document['_source']['url'] }}" class="external-link" target="_blank">
                        {{ document["_source"]["5_dcterms_title"] }}
                      </a>
                    </ng-container>
                    <ng-template #noUrl>
                      {{ document["_source"]["5_dcterms_title"] }}
                    </ng-template>
                  </ng-container>
                  <!-- <ng-container *ngIf="document['_source']['5_schema_author']">
                    <br />
                    <em>Autor*innen:</em>
                    {{ document["_source"]["5_schema_author"] }}
                  </ng-container> -->
                  <ng-container *ngIf="document['_source']['5_mo_haslearningtype']">
                    <br />
                    <!-- <em>Typ(en):</em> -->
                    <span *ngFor="
                        let typ of document['_source']['5_mo_haslearningtype'];
                        let isLast = last
                      ">
                      {{ typ.content }}{{ isLast ? "" : "; " }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="document['_source']['5_dcterms_language']">
                    <br />
                    <!-- <em>Sprache:</em> -->
                    <span *ngFor="
                        let lang of document['_source']['5_dcterms_language'];
                        let isLast = last
                      ">
                      {{ lang.LmAdd.lm_lang }}{{ isLast ? "" : "; " }}
                    </span>
                    <!-- <ng-container
                      *ngIf="document['_source']['5_fabio_hasdiscipline']"
                    >
                      <br />
                      <em>Fachdisziplin:</em>
                      <span
                        *ngFor="
                          let dis of document['_source'][
                            '5_fabio_hasdiscipline'
                          ];
                          let isLast = last
                        "
                      >
                        {{ dis.content }}{{ isLast ? "" : "; " }}
                      </span>
                    </ng-container> -->
                    <!-- <ng-container
                      *ngIf="document['_source']['5_mo_hasproficiencylevel']"
                    >
                      <br />
                      <em>Vorkenntnisse:</em>
                      {{
                        document["_source"]["5_mo_hasproficiencylevel"].content
                      }} -->
                    <!-- <span
                        *ngFor="
                          let level of document['_source'][
                            '5_mo_hasproficiencylevel'
                          ];
                          let isLast = last
                        "
                      >
                        {{ level.content }}{{ isLast ? "" : "; " }}
                      </span> -->
                    <!-- </ng-container> -->
                  </ng-container>
                </h2>
              </div>
              <!-- Zweite Spalte -->

              <div class="w-18 flex flex-col justify-between items-center md:flex hidden h-full">
                <!-- Icon oben -->
                <mat-icon class="mt-3 round-icon bg-primary-500 text-primary-50">
                  {{
                  getDoctypConfig(document["_source"]["facet_type"][0]).docIcon
                  }}
                </mat-icon>

                <!-- Bookmark Button unten -->
                <button mat-icon-button (click)="toggleBookmark($event, document)" class="mb-3">
                  <mat-icon class="text-primary-500">
                    {{
                    isBookmarked(document) ? "bookmark_added" : "bookmark_add"
                    }}
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <br />

      <!-- <div class="col-3">
          <pre>
            {{esQuery | json}}
          </pre>
        </div> -->
    </div>
  </div>
</div>