<div class="chip-container pl-3">
  <!-- <mat-chip-grid #chipGrid aria-label="Chip selection"> -->
    <!-- Volltextsuche -->
    <mat-chip
      class="mr-1 mt-2 mb-2 text-primary-50"
      highlighted="true"
      *ngIf="queryState.fullText"
    >
      <span class="text-primary-50">Volltext: {{ queryState.fullText }}</span>
    </mat-chip>

    <!-- Spezifische Feldfilter -->
    <mat-chip
      class="mr-1 mt-2 mb-2 text-primary-50"
      highlighted="true"
      *ngFor="let field of queryState.specificFields.fields"
    >
      <span class="text-primary-50">Filter: {{ field.value }}</span>
    </mat-chip>

    <!-- Datumsspanne -->
    <mat-chip
      class="mr-1 mt-2 mb-2 text-primary-50"
      highlighted="true"
      *ngIf="queryState.dateRange && queryState.dateRange.isActive"
    >
      <span class="text-primary-50"
        >Datumsspanne: {{ queryState.dateRange.start }} bis
        {{ queryState.dateRange.end }}</span
      >
    </mat-chip>

    <!-- DoctypVorfilter (nur inaktiv) -->
    <ng-container *ngFor="let doctyp of queryState.docTypeFilters | keyvalue">
      <mat-chip
        class="mr-1 mt-2 mb-2 text-primary-50"
        highlighted="true"
        *ngIf="!doctyp.value"
      >
        <span class="text-primary-50">Doctyp: {{ doctyp.key }}</span>
      </mat-chip>
    </ng-container>

    <!--  queryState.aggregations = { "facet_location": [ "Düsseldorf, Haus des Ministerpräsidenten, Haroldstraße 2" ], "facet_doctyp": [ "Protokoll" ], "facet_legislaturperiode": [ "2" ] } -->
    <!-- Aggregationen -->
    <!-- Aggregationen -->
    <ng-container *ngFor="let aggKey of objectKeys(queryState.aggregations)">
      <!-- Now this will display the aggKey as expected -->
      <mat-chip
        class="mr-1 mt-2 mb-2 text-primary-50"
        highlighted="true"
        *ngFor="let aggValue of queryState.aggregations[aggKey]"
      >
        <span class="text-primary-50">{{ aggKey }}: {{ aggValue }}</span>
      </mat-chip>
    </ng-container>
  <!-- </mat-chip-grid> -->
</div>
