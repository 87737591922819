import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SEARCH_CONFIG } from 'src/search-config';



@Injectable({
  providedIn: 'root'
})
export class ApiService {
  index = SEARCH_CONFIG.index;


  constructor(
    private http: HttpClient,
    ) {

    
   }

  search(query: any): Observable<any> {
    const body = {
      query: query,
      index: this.index // Make sure you have 'index' defined in your environment
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.wordpressThemeUrl + environment.searchUrl, JSON.stringify(body), { headers });
  }

  getDocument(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const query = { 
        "query": {
          "match": {
            "_id": id
          }
        }
    }

    const body = {
      query: query,
      index: this.index
    };

    return this.http.post(environment.wordpressThemeUrl + environment.searchUrl, JSON.stringify(body),{headers,responseType: 'json'});
  }



  getMinMax() {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const query = { 
      "aggs": {
        "min_range": {
          "min": {
            "field": SEARCH_CONFIG.range.gte
          }
        },
        "max_range": {
          "max": {
            "field": SEARCH_CONFIG.range.lte
          }
        }
      }
    }

    const body = {
      query: query,
      index: this.index
    };

    return this.http.post(environment.wordpressThemeUrl + environment.searchUrl, JSON.stringify(body),{headers,responseType: 'json'});
  }


  getFacetData() {

       // Definiere den Typ für das Akkumulator-Objekt in der reduce-Funktion
       const aggs = SEARCH_CONFIG.aggs.reduce<{ [key: string]: { terms: { field: string; size: number; }; }; }>((acc, agg) => {
        acc[agg.facetField] = {
          terms: {
            field: agg.facetField,
            size: 999 // Du kannst diesen Wert anpassen, um mehr oder weniger Begriffe zu erhalten
          }
        };
        return acc;
      }, {});

      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });

      const query = { 
        size: 0, // Teilt Elasticsearch mit, 0 Dokumente zurückzugeben
        aggs // Verwendet die dynamisch konstruierten Aggregationen
      }

      const body = {
        query: query,
        index: this.index
      };
  
      return this.http.post(environment.wordpressThemeUrl + environment.searchUrl, JSON.stringify(body), { 
        headers,
        responseType: 'json'  // Erwarte eine Textantwort
      });
    }

}


