import { SEARCH_CONFIG } from "src/search-config";


export const environment = {
  production: false,
  documentUrl: '/assets/api/elastic-document.php',
  searchUrl: '/assets/api/elastic-search.php',
  analyseUrl: '/assets/api/elastic-analyse.php',
  wordpressThemeUrl: './',
  wordpressPageUrl: '/',
  index: SEARCH_CONFIG.index,
  getIndices: '/assets/api/elastic-getIndices.php',

};
