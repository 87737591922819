import { Component, ElementRef, Input } from '@angular/core';
import { SubcontentComponent } from '../base/subcontent.component';
import { route, query } from '../../config';
import { LinkService, MappingElementWithFUD } from '../../services/link.service';
import { Router } from '@angular/router';
import { ConverterService } from '../../services/converter.service';
import { DateService } from '../../services/date.service';
import { SubcontentService } from '../../services/subcontent.service';
import { MappingSubcontentFile, MappingSubcontentLinkSubcontent, MappingSubcontentLinkcontent, UsefunctionSubcontent } from '../../types/MappingTypes';

@Component({
  selector: 'app-subcontent--link-item',
  templateUrl: '../base/subcontent.component.html',
  styleUrls: ['./link-item.subcontent.component.scss']
})
export class LinkItemSubcontentComponent extends SubcontentComponent{

  @Input() override representedMappingElement! : MappingSubcontentLinkcontent | MappingSubcontentLinkSubcontent;

  @Input() linkFromOtherDocument! : boolean | undefined;
  @Input() linkExtern! : boolean | undefined;
  @Input() linkTarget! : string | undefined;
  @Input() linkFudIndex! : string | undefined;

  constructor(
    private router : Router,

    protected override linkService : LinkService,
    protected override converterService : ConverterService,
    protected override dateService : DateService,
    protected override componentRef : ElementRef,
    protected override subcontentService : SubcontentService
  ){
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService
    );
  }

  override init() {
    switch(this.representedMappingElement.typ){
    case 'link_group':
      this.handleLinkSubcontentGroup();
      break;
    case 'link_fud':
    case 'link_string':
      this.handleLinkItem();
      break;
    default:
      switch(this.representedMappingElement.etype){
      case 'linkcontent':
        this.handleLinkcontent();
        break;
      default:
        console.error('Created LinkItemSubcontentComponent but is not of typ link_group, link_fud, link_string or etype linkcontent!');
        // in case something unexpected happens, let the super class handle it
        super.init();
        break;
      }
      break;
    }
  }

  /*
  *   creates a new subcontent element that inserts the link content inside a html element
  *   the html element can be:
  *   anchor tag - for links that should show up as links
  *   span - for links that should show up as images or plain text
  * 
  *   in the case of simple link contents:
  *   the next subcontent component gets all the fake information such that it thinks it creates a normal mapped element
  * 
  *   in the case of link_group:
  *   the next subcontent components get handled by the handleLinkSubcontent()-function
  */
  private async handleLinkcontent() {
    if(!this.representedMappingElement.subcontent){
      console.error('Expected a subcontent inside the link content!');
      return;
    }
    // this next element created below will insert the text inside the anchor-tag
    const wrapper = this.handleBoxing(this.representedMappingElement.subcontent.length);
    
    this.representedMappingElement.subcontent.forEach((linkContent : any, index : number)=>{
      console.warn(linkContent);
      this.createLinkSubComponent({
        representedMappingElement : linkContent,
        id : this.id + ' ' + index,
        exampleElement : this.exampleElement,
        wrapper : wrapper,
        childCount : 1,
        isLastBlockElement : this.isLastBlockElement,
        kTitles : this.kTitles,
        mTitles : this.mTitles,
        linkFromOtherDocument : this.linkFromOtherDocument,
        linkExtern : this.linkExtern,
        linkTarget : this.linkTarget,
        linkFudIndex : this.linkFudIndex
      });
    });
  }
  
  async handleLinkSubcontentGroup(){
    if(!this.representedMappingElement.subcontent){
      console.error('Expected a subcontent inside the link subcontent group!');
      return;
    }
    await this.changeToAnchorTagIfNeeded();

    const childCount = this.representedMappingElement.subcontent.length;
    const wrapper = this.handleBoxing(childCount);    

    this.representedMappingElement.subcontent.forEach((mappingSubcontent : any, index : number) => {
      this.createLinkSubComponent({
        representedMappingElement : mappingSubcontent,
        id : this.id + ' ' + index,
        exampleElement : this.exampleElement,
        wrapper : wrapper,
        childCount : childCount,
        isLastBlockElement : index === childCount -1,
        kTitles : this.kTitles,
        mTitles : this.mTitles,
        linkFromOtherDocument : this.linkFromOtherDocument,
        linkExtern : this.linkExtern,
        linkTarget : this.linkTarget,
        linkFudIndex : this.linkFudIndex
      });
    });
  }

  async handleLinkItem(){
    const mapping = this.representedMappingElement as MappingSubcontentLinkSubcontent;
    await this.changeToAnchorTagIfNeeded();
    let content;
    if(this.linkFromOtherDocument){
      if(!this.linkFudIndex || !this.linkTarget){
        console.error('Error for fud document: ',this.exampleElement,' and mapping part: ',mapping, ' and fudindex: ',this.linkFudIndex);
        throw new Error('SubcontentComponent with typ:link_fud that represents a inlink from another document expects an fud index and a link target.');
      }
      content = await this.linkService.getContentHttp(
        mapping,
        this.exampleElement,
        this.linkFudIndex,
        this.linkTarget
      );      
    } else {
      content = this.linkService.getContentNoHttp(
        mapping,
        this.exampleElement
      );
    }
    if(!content){
      this.wrapper?.oneChildMoreReady();
      console.warn('No content could be found for link mapping element ',mapping,' with fud document part: ',this.exampleElement);
      console.warn(this.linkTarget, this.linkFudIndex);
      return;
    }
    let str = content.documentValue;

    if(mapping.display === 'image'){
      this.handleImage(content);
    } else {
      if(mapping.usefunction === 'dateformat' && mapping.subcontent){
        const usefunctionSubcontent = mapping.subcontent[0] as UsefunctionSubcontent;
        if(usefunctionSubcontent){
          str = this.dateService.formatDate(str,
            usefunctionSubcontent.epart,
            usefunctionSubcontent.elang || 'de-DE');
        }
      }
      this.subcontent.nativeElement.innerHTML = str;
      this.subcontent.nativeElement.classList.add('link-content-span');
  
      // must stay behind all the asynchronous function calls because it sends a message that this element is ready
      const wrapper = this.handleBoxing(0);
      this.styleSpan(this.subcontent.nativeElement, wrapper);
    }
  }

  private async changeToAnchorTagIfNeeded() : Promise<void>{
    const mapping = this.representedMappingElement as MappingSubcontentLinkSubcontent;
    return new Promise((resolve)=>{
      const displayInlinkContentWithoutTarget = !this.linkTarget && !this.linkFromOtherDocument;
      if(displayInlinkContentWithoutTarget || mapping.display === 'text'){
        // no anchor tag
        mapping.etype = 'json';
        mapping.fudfeldtyp = undefined;
      } else {
        if(mapping.display === 'image'){
          mapping.etype = 'anhang';
        } else { // display: link
          setTimeout(()=>{
            this.outerContainer.clear();
            this.outerContainer.createEmbeddedView(this.outerLink);
          });
          if(mapping.etype === 'anhang'){
            mapping.etype = 'json';
          }
        }
      }
      if(mapping.display === 'text' || mapping.display === 'image' || !this.linkTarget){
        resolve();
      }
      if(mapping.display === 'link' && this.linkTarget){
        setTimeout(()=>{
          const content = this.content.nativeElement as HTMLAnchorElement;
          if (!this.linkExtern) {
            content.setAttribute('href', '#/'+route+'/'+ this.linkTarget); // just for display purposes
            content.addEventListener('click', (event : any) => {
              event.preventDefault();
              if (query) {
                this.router.navigate([route], {
                  queryParams: {
                    id: this.linkTarget
                  },
                  queryParamsHandling: 'merge'
                });
              } else {
                this.router.navigate([route + '/' + this.linkTarget]);
              }
            });
          } else {
            content.setAttribute('href', this.linkTarget ? this.linkTarget : '');
            content.setAttribute('target', '_blank'); // open in new tab
          }
          resolve();
        });
      }
    });
  }

  handleImage(content : MappingElementWithFUD){
    let mapping = JSON.parse(JSON.stringify(content.mappingValue)) as MappingSubcontentFile;
    let exampleElement : string | {filename:string, sourcename:string} = content.documentValue;
    if(!mapping){
      mapping = {
        typ : 'file',
        fudfeldtyp : 'kf'
      } as MappingSubcontentFile;
      exampleElement = {
        filename : content.documentValue,
        sourcename : content.documentValue
      };
    }
    mapping.typ = 'file';
    const wrapper = this.handleBoxing(1);
    console.warn(mapping);
    this.createSubComponent({
      representedMappingElement : mapping,
      id : this.id + ' ' + 1,
      exampleElement : exampleElement,
      wrapper : wrapper,
      childCount : 1,
      isLastBlockElement : this.isLastBlockElement,
      kTitles : this.kTitles,
      mTitles : this.mTitles
    });
  }
}