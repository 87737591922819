<div #wrap>
  <div *ngIf="hidden" class="center">
    <mat-spinner></mat-spinner>
  </div>
  <div [hidden]="hidden">
    <h1 #heading>
      <ng-container #childrenContainer></ng-container>
    </h1>
    <div #subcontent class="col-md-12">
      <ng-container #childrenContainer></ng-container>
    </div>
  </div>
</div>
