import { Component } from '@angular/core';
import { SEARCH_CONFIG } from 'src/search-config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
   title = SEARCH_CONFIG.title;
   img = SEARCH_CONFIG.logoImg;
}
