import { Component } from '@angular/core';
import { QueryStateService } from 'src/app/services/query-state.service';
import { SEARCH_CONFIG } from 'src/search-config';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-pre-filter',
  templateUrl: './pre-filter.component.html',
  styleUrl: './pre-filter.component.scss'
})
export class PreFilterComponent {
    docTypes = SEARCH_CONFIG.docTyps; // Add this line
    // Add a new property to track the toggle state
    docTypeToggleStates: { [docType: string]: boolean } = {};


  constructor(
    public queryStateService: QueryStateService,
    private changeDetectorRef: ChangeDetectorRef,

    ) {

  }

  ngOnInit() {
  // Initialisiere die Toggle-States basierend auf dem gespeicherten Zustand
  this.initializeToggleStates();
    
  // Abonniere Änderungen im QueryState, um die UI bei Bedarf zu aktualisieren
  this.subscribeToQueryStateChanges();
  }

  initializeToggleStates() {
    const savedDocTypeFilters = this.queryStateService.getQueryState().docTypeFilters || {};
  
    this.docTypes.forEach(docType => {
      // Set the toggle state based on the saved state or default to true
      this.docTypeToggleStates[docType.docValue] = savedDocTypeFilters.hasOwnProperty(docType.docValue) ? savedDocTypeFilters[docType.docValue] : true;
    });
  }
  
  resetToggleStatesBasedOnQueryState(docTypeFilters: { [key: string]: boolean }) {
    this.docTypes.forEach(docType => {
      // If docTypeFilters is empty or not defined, all toggles should default to true
      this.docTypeToggleStates[docType.docValue] = docTypeFilters[docType.docValue] !== false;
    });
    this.changeDetectorRef.detectChanges(); // Trigger change detection
  }
  

  
subscribeToQueryStateChanges() {
  this.queryStateService.queryStateObservable.subscribe(queryState => {
      // Aktualisiere die Toggle-States basierend auf dem aktuellen QueryState
      this.resetToggleStatesBasedOnQueryState(queryState.docTypeFilters);
  });
}



  // This method is called when a slide toggle changes state
  onDocTypeToggleChange(docTypeLabel: string, isChecked: boolean) {
    const currentDocTypeFilters = this.queryStateService.getQueryState().docTypeFilters || {};
    
    // Aktualisiere den Zustand für diesen Dokumenttyp
    currentDocTypeFilters[docTypeLabel] = isChecked;
    
    // Aktualisiere den globalen Zustand mit den neuen docTypeFilters
    this.queryStateService.setDocTypeFilters(currentDocTypeFilters);
}





}
