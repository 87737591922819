import { Component } from '@angular/core';
import { QueryStateService } from 'src/app/services/query-state.service';
import { SEARCH_CONFIG } from 'src/search-config';
import { QueryService } from 'src/app/services/query.service';

@Component({
  selector: 'app-aggregations',
  templateUrl: './aggregations.component.html',
  styleUrl: './aggregations.component.scss'
})
export class AggregationsComponent {
  aggregations: any;
  sortOption: string="alphabetical";


  constructor(
    public queryStateService: QueryStateService,
    private queryService: QueryService
    ) {

  }

  ngOnInit() {
    this.queryService.response$.subscribe(data => {
      this.aggregations = data?.aggregations;
    });
  }

  getAggregationKeys(): string[] {
    // Filter the aggs based on the show property
    const visibleAggs = SEARCH_CONFIG.aggs.filter((agg) => agg.show);

    // Map to get the ordered keys
    const orderedKeys = visibleAggs.map((agg) => agg.facetField);

    // Get the keys from the actual aggregations object
    const aggregationKeys = Object.keys(this.aggregations);

    // Return only the keys that are both in orderedKeys and aggregationKeys
    return orderedKeys.filter((key) => aggregationKeys.includes(key));
  }
   // Funktion, um die Konfiguration basierend auf aggKey zu finden
   getAggConfig(aggKey: string) {
    const config = SEARCH_CONFIG.aggs.find(agg => agg.facetField === aggKey);
    return config || { facetLabel: aggKey, facetIcon: 'default_icon' };
  }

  activateFacet(key: string, aggKey: string) {
    console.log(key,aggKey);
    this.queryStateService.addAggregation(aggKey, key);
  }
  
  deactivateFacet(key: string, aggKey: string) {
    this.queryStateService.removeAggregation(aggKey, key);
  }

  onCheckboxChange(event: any, facetKey: string, aggKey: string): void {
    if (event.checked) {
      this.queryStateService.addAggregation(aggKey, facetKey);
    } else {
      this.queryStateService.removeAggregation(aggKey, facetKey);
    }
  }

  isTermSelected(aggKey: string, term: string): boolean {
    return this.queryStateService.isAggregationSelected(aggKey, term);
  }

  getSortedFacets(aggKey: string): any[] {
    const facets = this.aggregations[aggKey]?.buckets || [];
    if (this.sortOption === "alphabetical") {
      return facets.sort((a: any, b: any) => a.key.localeCompare(b.key));
    } else if (this.sortOption === "count") {
      return facets.sort((a: any, b: any) => b.doc_count - a.doc_count);
    }
    return facets;
  }
}
