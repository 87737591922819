<mat-card [ngClass]="{ active: queryStateService.isFullTextSearchActive() }">
  <div
    class="d-flex justify-content-start align-items-center headline-searchForm text-primary-500 mb-0 mb-3 border-b-2 border-primary-500"
  >
    <mat-icon class="mr-2 text-primary-500">search</mat-icon>
    <p>Full text search</p>
  </div>

  <mat-card-content>
    <div class="d-flex justify-content-center align-items-center">
      <mat-form-field appearance="outline" class="w-75 pr-2">
        <span matPrefix>
          <mat-icon class="ml-2">search</mat-icon>
        </span>
        <input
          class="bg-white"
          matInput
          placeholder="Search term"
          [(ngModel)]="fullText"
          (ngModelChange)="updateQueryState()"
        />
        <button
          *ngIf="fullText"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- FullTextSearchComponent template -->
      <mat-form-field appearance="outline" class="w-25">
        <mat-label>Sort order</mat-label>
        <mat-select
          [(ngModel)]="selectedSortOption"
          (ngModelChange)="updateSortOption($event)"
        >
          <mat-option *ngFor="let option of sortOptions" [value]="option">
            {{ option.sortName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-icon
        class="text-primary ml-2 mb-4 mr-2"
        matTooltip='You can use AND, OR, NOT, *, " " to refine your search.'
        >info</mat-icon
      >
    </div>
  </mat-card-content>
</mat-card>
