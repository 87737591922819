import { Component, OnInit } from '@angular/core';
import { QueryService } from '../../services/query.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SEARCH_CONFIG } from 'src/search-config';
import { QueryStateService } from '../../services/query-state.service';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  
})
export class SearchFormComponent {
  // FormGroup für das Formular
  searchForm: FormGroup | undefined;

  // Array von spezifischen Feldern aus der Konfiguration
  specificFields = SEARCH_CONFIG.specificFields;

  // Array für die Chips
  fruits: string[] = [];

  constructor(
    private fb: FormBuilder, 
    private queryService: QueryService,
    private queryStateService: QueryStateService) {

  }

  ngOnInit() {
    this.submitSearch();
  }

  // Absenden der Suchanfrage
  submitSearch() {
    
  }

  resetSearch() {
    // Reset the internal query state
    this.queryStateService.resetQueryState();

    // ...reset other components/forms as needed
  }
  

}
