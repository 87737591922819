import { Component } from '@angular/core';
import { QueryStateService } from '../../../services/query-state.service';
import { SEARCH_CONFIG } from 'src/search-config';

@Component({
  selector: 'app-full-text-search',
  templateUrl: './full-text-search.component.html',
  styleUrls: ['./full-text-search.component.scss']
})
export class FullTextSearchComponent {
  fullText: string = '';
  sortOptions = SEARCH_CONFIG.sort;
  selectedSortOption: { sortField: string; sortMethod: string } | null = null;

  constructor(public queryStateService: QueryStateService) {
    this.queryStateService.queryStateObservable.subscribe(queryState => {
      this.fullText = queryState.fullText;

      // Set the default sort option to the first element of the sortOptions array
      this.selectedSortOption = this.sortOptions[0] || null;

      // If the query state has a sort option, use it; otherwise, use the default
      if (queryState.sortOption) {
        this.selectedSortOption = queryState.sortOption;
      }
    });
  }

  ngOnInit() {
    this.queryStateService.queryStateObservable.subscribe(queryState => {
      this.fullText = queryState.fullText;
      // Set the selectedSortOption to the first option from sortOptions or use the one from queryState
      this.selectedSortOption = queryState.sortOption || this.sortOptions[0];
    });
  }

  updateSortOption(selectedOption: any) {
    this.selectedSortOption = selectedOption;
    this.queryStateService.setSortOption(selectedOption);
  }

  updateQueryState() {
    this.queryStateService.setFullText(this.fullText);
  }

  clearSearch() {
    this.fullText = '';
    this.updateQueryState(); // This will clear the fullText in the query state as well
  }
}
