import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { standardEnvironment, useExpress } from '../config';
import { environment } from 'src/environments/environment';

type Request = 'getIndices' | 'getDocument' | 'search' | 'getMapping';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private urlPrefix : string;

  constructor(
    private http: HttpClient,
  ) {
    if(useExpress){
      this.urlPrefix = 'http://localhost:3000/';
    } else {
      this.urlPrefix = standardEnvironment.wordpressThemeUrl;
    }
  }

  private getURL(req : Request, index? : string, id? : string) : string{
    let url = this.urlPrefix;
    switch(req){
    case 'getIndices':
      if(useExpress){
        url += 'indices/stats';
      } else {
        url += standardEnvironment.getIndices;
      }
      break;
    case 'getDocument':
      if(useExpress){
        if(!index || !id){
          throw new Error('If express should be used, the getDocument-query needs the index and id argument!');
        }
        url += 'doc/'+index+'/'+id;
      } else {
        url += standardEnvironment.documentUrl;
      }
      break;
    case 'search':
      if(useExpress){
        if(!index){
          throw new Error('If express should be used, the search-query needs the index argument!');
        }
        url += 'search/'+index;
      } else {
        // TODO Implement the search query for php
        throw new Error('The search query has not yet been implemented for php!');
      }
      break;
    case 'getMapping':
      if(useExpress){
        if(!index){
          throw new Error('If express should be used, the getMapping-query needs the index argument!');
        }
        url += 'mapping/'+index;
      } else {
        // TODO Implement the getMapping query for php
        throw new Error('The getMapping query has not yet been implemented for php!');
      }
      break;
    }
    return url;
  }

  public getElasticIndices(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.http.get(this.getURL('getIndices')).subscribe(
        (stats: any) => {
          if (stats && stats.indices) {
            resolve(Object.keys(stats.indices));
          } else {
            console.error('Unexpected data format:', stats);
            reject(new Error('Unexpected data format'));
          }
        },
        (error) => {
          console.error('Error:', error);
          reject(error);
        }
      );
    });
  }

  public getDoctypSearch(index: string) : Promise<any[]> {
    return new Promise((resolve,reject)=>{
      this.http.post(this.getURL('search', index),
        {
          query: {
            match_all: {}
          }
        }).subscribe(
        (hits : any) => {
          resolve(Object.values(hits));
        },
        (error) => {
          console.error('Error:', error);
          reject(error);
        }
      );
    });
  }
  
  public getMapping(index: string) : Promise<any>{
    return new Promise((resolve,reject)=>{
      this.http.get(this.getURL('getMapping', index)).subscribe(
        (result : any) => {
          resolve(result);
        },
        (error) => {
          console.error('Error:', error);
          reject(error);
        }
      );
    });
  }

  public getDocument(index: string, id: string){

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const query = {
      'query': {
        'multi_match': {
          'query': id,
          'fields': ['_id']
        }
      }
    };
    
    const body = {
      query: query,
      index: environment.index
    };

    const url = this.getURL('getDocument', index, id);
    console.log(url, body);
    return new Promise((resolve,reject)=>{
      if(useExpress){
        this.http.get(url).subscribe(
          (result : any) => {
            resolve(result);
          },
          (error) => {
            console.error('Error:', error);
            reject(error);
          }
        );
      } else {
        console.log(url);
        console.log(JSON.stringify(body));
        this.http.post(url, JSON.stringify(body) ,{headers,responseType: 'json'}).subscribe(
          (result : any) => {
            resolve(result);
          },
          (error) => {
            console.error('Error:', error);
            reject(error);
          }
        );
      }
    });
  }

  getDocumentsWhereFieldExists(index : string, field : string) : Promise<any>{
    return new Promise((resolve,reject)=>{
      this.http.post(this.getURL('search', index),
        {
          'query': {
            'bool': {
              'must': [
                {
                  'exists': {
                    'field': field
                  }
                }
              ]
            }
          }
        }).subscribe(
        (result : any) => {
          resolve(result);
        },
        (error) => {
          console.error('Error:', error);
          reject(error);
        }
      );
    });
  }
}