import { Component, ElementRef, Input } from '@angular/core';
import { SubcontentComponent } from '../base/subcontent.component';
import { LinkService } from '../../services/link.service';
import { ConverterService } from '../../services/converter.service';
import { DateService } from '../../services/date.service';
import { SubcontentService } from '../../services/subcontent.service';
import { MappingSubcontent, MappingSubcontentGroup } from '../../types/MappingTypes';

@Component({
  selector: 'app-subcontent--group',
  templateUrl: '../base/subcontent.component.html',
  styleUrls: ['./group.subcontent.component.scss']
})
export class GroupSubcontentComponent extends SubcontentComponent{

  @Input() override representedMappingElement! : MappingSubcontentGroup;

  constructor(
    protected override linkService : LinkService,
    protected override converterService : ConverterService,
    protected override dateService : DateService,
    protected override componentRef : ElementRef,
    protected override subcontentService : SubcontentService
  ){
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService
    );
  }

  override init() {
    switch(this.representedMappingElement.typ){
    case 'group':
      this.handleGroup();
      break;
    default:
      switch(this.representedMappingElement.etype){
      default:
        console.error('Created GroupSubcontentComponent but is not of typ group!');
        // in case something unexpected happens, let the super class handle it
        super.init();
      }
    }
  }

  handleGroup(){
    const mappingSubcontents = this.representedMappingElement.subcontent;
    const amountOfChildren = mappingSubcontents.length;
    const wrapper = this.handleBoxing(amountOfChildren);
    mappingSubcontents.forEach((mappingSubcontent: MappingSubcontent, subcontentIndex: number)=>{
      this.createNextSubcontentComponent(this.exampleElement, mappingSubcontent, subcontentIndex, wrapper, amountOfChildren, this.isLastBlockElement);
    });
  }

}