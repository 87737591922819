<div class="container small-view pt-3">
  <app-full-text-search></app-full-text-search>
  <app-specific-field-search></app-specific-field-search>
  <app-pre-filter></app-pre-filter>
  <!-- <app-date-range></app-date-range> -->
  <app-aggregations></app-aggregations>

  <br />
  <br />
</div>

<div class="container search-view">
  <button
    mat-flat-button
    class="col-md-8"
    color="primary"
    (click)="submitSearch()"
    (keyup.enter)="submitSearch()"
  >
    <mat-icon class="mr-3 text-primary-50">search</mat-icon>
    <span class="text-primary-50">Search</span>
  </button>
  <button mat-flat-button class="col-md-4" (click)="resetSearch()">
    <mat-icon class="mr-3">refresh</mat-icon> Reset
  </button>
</div>
