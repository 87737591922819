export const SEARCH_CONFIG = {
  index: "fud_n4mvh_doctyp5web",
  title: "NFDI4Memory Data Literacy Virtual Hub",
  logoImg: "/assets/img/Logo_for_memory_RGB.svg",
  debug: true,
  docTyps: [
    {
      docLabel: "Texte",
      docValue: "Text",
      docField: "facet_type",
      docIcon: "article",
    },
    {
      docLabel: "Bücher",
      docValue: "Buch",
      docField: "facet_type",
      docIcon: "menu_book",
    },
    {
      docLabel: "Videos",
      docValue: "Video",
      docField: "facet_type",
      docIcon: "videocam",
    },
    {
      docLabel: "Quiz",
      docValue: "Quiz",
      docField: "facet_type",
      docIcon: "quiz",
    },
    {
      docLabel: "Tutorials",
      docValue: "Tutorial",
      docField: "facet_type",
      docIcon: "history_edu",
    },
    {
      docLabel: "Lernplattformen",
      docValue: "Lernplattform",
      docField: "facet_type",
      docIcon: "school",
    },
    {
      docLabel: "Anderes",
      docValue: "Anderes",
      docField: "facet_type",
      docIcon: "more_horiz",
    },
  ],
  specificFields: [
    {
      fieldName: "facet_type",
      uiTitle: "Lernressourcentyp",
      facet: "facet_type",
    },
    // {
    //   fieldName: "facet_language",
    //   uiTitle: "Sprache",
    //   facet: "facet_language",
    // },
  ],
  range: {
    gte: "sort_date",
    lte: "sort_date",
  },
  sort: [
    { sortName: "Relevanz", sortField: "", sortMethod: "" },
    {
      sortName: "Titel absteigend",
      sortField: "sort_title",
      sortMethod: "ASC",
    },
    {
      sortName: "Titel aufsteigend",
      sortField: "sort_title",
      sortMethod: "DESC",
    },
  ],
  aggs: [
    {
      facetField: "facet_type",
      facetLabel: "Typ",
      facetIcon: "Typ",
      show: "true"
    },
    {
      facetField: "facet_author",
      facetLabel: "Autor*innen",
      facetIcon: "person",
      show: "true"
    },
    {
      facetField: "facet_language",
      facetLabel: "Sprache",
      facetIcon: "language",
      show: "true"
    },
    {
      facetField: "facet_target",
      facetLabel: "Zielgruppe",
      facetIcon: "map",
      show: "true"
    },
    {
      facetField: "facet_discipline",
      facetLabel: "Fachdisziplin",
      facetIcon: "article",
      show: "true"
    },
    {
      facetField: "facet_level",
      facetLabel: "Vorkenntnisse",
      facetIcon: "account_balance",
      show: "true"
    },
  ],
};
