import { ChangeDetectorRef, Component, OnInit, OnDestroy } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { QueryStateService } from "src/app/services/query-state.service";

@Component({
  selector: "app-date-range",
  templateUrl: "./date-range.component.html",
  styleUrls: ["./date-range.component.scss"],
})
export class DateRangeComponent implements OnInit, OnDestroy {
  dateRangeForm: FormGroup;
  min: string | undefined;
  max: string | undefined;

  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private queryState: QueryStateService,
    private api: ApiService,
    private cdr: ChangeDetectorRef
  ) {
    this.dateRangeForm = this.fb.group(
      {
        start_range: ["", this.validateDate()],
        end_range: ["", this.validateDate()],
        isActive: [false],
      },
      { validator: this.validateDateRange.bind(this) }
    );
  }

  ngOnInit() {
    this.fetchMinMaxDates();
    this.subscribeToQueryState();

    this.dateRangeForm.valueChanges.subscribe(
      ({ start_range, end_range, isActive }) => {
        if (isActive) {
          const startISO = this.formatDateToISO(start_range);
          const endISO = this.formatDateToISO(end_range);
          if (startISO && endISO) {
            this.queryState.setDateRange(startISO, endISO, isActive);
          }
        }
      }
    );
  }

  fetchMinMaxDates() {
    this.api.getMinMax().subscribe(
      (result: any) => {
        if (
          result.aggregations &&
          result.aggregations.min_range &&
          result.aggregations.max_range
        ) {
          this.min =
            this.formatDateToGerman(result.aggregations.min_range.value) ??
            undefined;
          this.max =
            this.formatDateToGerman(result.aggregations.max_range.value) ??
            undefined;
          this.dateRangeForm.patchValue({
            start_range: this.min,
            end_range: this.max,
          });
        } else {
          console.error("Expected aggregation data is missing in the response");
        }
      },
      (error) => {
        console.error("Error fetching min/max dates:", error);
      }
    );
  }

  subscribeToQueryState() {
    this.subscription.add(
      this.queryState.queryStateObservable.subscribe((queryState) => {
        if (queryState.dateRange) {
          const startRangeValue = this.formatDateToGerman(
            queryState.dateRange.start
          );
          const endRangeValue = this.formatDateToGerman(
            queryState.dateRange.end
          );

          if (queryState.dateRange.isActive) {
            this.dateRangeForm.setValue({
              start_range: startRangeValue ?? "",
              end_range: endRangeValue ?? "",
              isActive: queryState.dateRange.isActive,
            });
          } else {
            this.dateRangeForm.patchValue({
              isActive: queryState.dateRange.isActive,
            });
          }
        }
        this.cdr.detectChanges();
      })
    );
  }

  resetFormToDefault() {
    this.dateRangeForm.reset({
      start_range: this.min ?? "",
      end_range: this.max ?? "",
      isActive: false,
    });
  }

  submitRange() {
    const isActive = !this.dateRangeForm.get("isActive")?.value;
    const startRange = this.formatDateToISO(
      this.dateRangeForm.get("start_range")?.value
    );
    const endRange = this.formatDateToISO(
      this.dateRangeForm.get("end_range")?.value
    );

    if (startRange && endRange) {
      this.queryState.setDateRange(startRange, endRange, isActive);
      this.cdr.detectChanges();
    } else {
      console.error("Invalid date format. Please use the format DD.MM.YYYY.");
    }
  }

  validateDate(): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      if (!value) return null;

      const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
      const yearPattern = /^\d{4}$/;

      if (datePattern.test(value)) {
        return null;
      } else if (yearPattern.test(value)) {
        return null;
      } else {
        return { invalidDate: true };
      }
    };
  }

  validateDateRange(group: FormGroup) {
    const start = this.formatDateToISO(group.get("start_range")?.value);
    const end = this.formatDateToISO(group.get("end_range")?.value);

    if (start && end && new Date(start) > new Date(end)) {
      return { rangeError: true };
    } else {
      return null;
    }
  }

  formatStartRange() {
    let startValue = this.dateRangeForm.get("start_range")?.value;
    if (/^\d{4}$/.test(startValue)) {
      startValue = `01.01.${startValue}`;
    }
    this.dateRangeForm.patchValue({ start_range: startValue });
  }

  formatEndRange() {
    let endValue = this.dateRangeForm.get("end_range")?.value;
    if (/^\d{4}$/.test(endValue)) {
      endValue = `31.12.${endValue}`;
    }
    this.dateRangeForm.patchValue({ end_range: endValue });
  }

  formatDateToISO(date: string | null): string | null {
    if (!date) return null;

    const [day, month, year] = date.split(".");
    if (day && month && year) {
      return `${year}-${month}-${day}`;
    }

    return null;
  }

  formatDateToGerman(date: string | null): string | undefined {
    if (!date) return undefined;

    const [year, month, day] = date.split("-");
    if (year && month && day) {
      return `${day}.${month}.${year}`;
    }

    return undefined;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
