import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { BookmarkDialogComponent } from "../dialogs/bookmark-dialog/bookmark-dialog.component";
import { BookmarkService } from "../services/bookmark.service";
import { QueryService } from "../services/query.service";
import { Subject, Subscription } from "rxjs";
import { BookmarkItem } from "src/app/services/bookmark.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SEARCH_CONFIG } from "src/search-config";

@Component({
  selector: "app-document-view",
  templateUrl: "./document-view.component.html",
  styleUrl: "./document-view.component.scss",
})
export class DocumentViewComponent implements OnInit, OnDestroy {
  triggerAnimation: boolean = false;
  document: any;
  documentIds: string[] = [];
  currentIndex: number = 0;
  private idsSubscription: Subscription | undefined;

  idManager!: Subject<number>;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    public bookmarkService: BookmarkService,
    private snackBar: MatSnackBar,
    private router: Router,
    public queryService: QueryService
  ) {}
  // SET URL ID
  ngOnInit() {
    this.idManager = new Subject<number>();

    // const id = this.route.snapshot.paramMap.get('id');
    this.idsSubscription = this.queryService.ids$.subscribe((ids) => {
      this.documentIds = ids;
      this.route.paramMap.subscribe((params) => {
        const id = params.get("id");
        if (id) {
          this.currentIndex = this.documentIds.indexOf(id);
          this.fetchData(id);
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.idsSubscription) {
      this.idsSubscription.unsubscribe();
    }
  }

  fetchData(id: any) {
    console.log(id);
    this.apiService.getDocument(id).subscribe((res) => {
      this.document = res;
      this.document = this.document.hits.hits[0];
      this.cdr.detectChanges();
    });
  }

  navigateToDocument(documentId: string) {
    this.router.navigate(["/document", documentId]);
  }

  goToPrevDocument() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.navigateToDocument(this.documentIds[this.currentIndex]);
    }
  }

  goToNextDocument() {
    if (this.currentIndex < this.documentIds.length - 1) {
      this.currentIndex++;
      this.navigateToDocument(this.documentIds[this.currentIndex]);
    }
  }

  openDialog(): void {
    this.dialog.open(BookmarkDialogComponent, {
      data: this.bookmarkService.getMerklisteItems(),
    });
  }

  get merklisteCount(): number {
    return this.bookmarkService.getMerklisteCount();
  }

  toSearch() {
    this.router.navigate(["/"]);
  }

  toggleBookmark(event: Event, document: any): void {
    event.stopPropagation(); // Prevent the event from bubbling up
    console.log(document);
    // Create the bookmark item
    const bookmarkItem: BookmarkItem = {
      id: document._id,
      title: document["_source"]["display_title"],
    };

    // Toggle the bookmark in the service
    this.bookmarkService.toggleItem(bookmarkItem.id, bookmarkItem.title);

    // Show feedback to the user
    const message = this.bookmarkService.isInMerkliste(bookmarkItem.id)
      ? "Zur Merkliste hinzugefügt"
      : "Von Merkliste entfernt";
    this.snackBar.open(message, "", {
      duration: 2000,
    });
  }

  isBookmarked(document: any): boolean {
    return this.bookmarkService.isInMerkliste(document._id);
  }

  getDoctypConfig(key: string) {
    const config = SEARCH_CONFIG.docTyps.find(
      (docTyp) => docTyp.docValue === key
    );
    return config || { docLabel: key, docIcon: "" };
  }
}
