import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface BookmarkItem {
  id: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {

  private merklisteSubject = new BehaviorSubject<BookmarkItem[]>([]);
  private merkliste = new Map<string, { id: string, title: string }>();

  constructor() {
    this.loadState();
    this.merklisteSubject.next(this.getMerklisteItems());
  }



  getMerklisteCount(): number {
    return this.merkliste.size;
  }

  // This should be correct if the saved data is correct, but verify the loaded structure
  private loadState() {
    const saved = localStorage.getItem('merkliste');
    const savedItems = saved ? JSON.parse(saved) : [];
    this.merkliste = new Map(savedItems.map((item: any) => [item.id, item]));
}

  
  private saveState() {
    localStorage.setItem('merkliste', JSON.stringify(Array.from(this.merkliste.entries())));
}



  toggleItem(itemId: string, title: string) {
    if (this.merkliste.has(itemId)) {
      this.merkliste.delete(itemId);
    } else {
      this.merkliste.set(itemId, { id: itemId, title: title });
    }
    this.saveState();
    this.merklisteSubject.next(this.getMerklisteItems());
  }

  isInMerkliste(itemId: string): boolean {
      return this.merkliste.has(itemId);
  }

  getMerklisteItems(): { id: string, title: string }[] {
      return Array.from(this.merkliste.values());
  }

  getMerklisteItemsObservable() {
    return this.merklisteSubject.asObservable();
  }
}
