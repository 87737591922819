<div class="document-view-container d-flex flex-row">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2">
        <button
          mat-flat-button
          color="primary"
          class="mt-3"
          (click)="toSearch()"
        >
          <mat-icon class="text-primary-50">arrow_back_ios</mat-icon>
          <span class="text-primary-50">Search</span>
        </button>
      </div>
<ng-container *ngIf="document?._source">

      <div class="col-md-12">
        <div class="content mb-5 pb-5 mt-5">
          <div class="d-flex justify-content-start">
            <div class="flex items-center justify-center">
              <div
                class="w-12 h-12 bg-primary-500 rounded-full flex items-center justify-center"
              >
                <mat-icon class="text-primary-50">
                  {{
                    getDoctypConfig(document["_source"]["facet_type"][0]).docIcon
                  }}
                </mat-icon>
              </div>
            </div>

            <span *ngIf="this.document" class="">
              <!-- <app-converter-view
                [$id]="idManager.asObservable()"
                [mappingComponentType]="'header'"
                class="custom-converter"
              ></app-converter-view> -->
            </span>
          </div>

          <div class="d-flex justify-content-between position-relative">
            <button
              (click)="goToPrevDocument()"
              mat-icon-button
              class="mr-4 prev"
              matTooltip="Vorheriges Dokument"
              [disabled]="currentIndex <= 0"
            >
              <mat-icon>arrow_back_ios</mat-icon>
            </button>

            <button
              (click)="goToNextDocument()"
              mat-icon-button
              class="ml-4 next"
              matTooltip="Nächstes Dokument"
              [disabled]="currentIndex >= documentIds.length - 1"
            >
              <mat-icon>arrow_forward_ios</mat-icon>
            </button>
          </div>

          <mat-card class="p-3 myShadow">
            <app-converter-view
              [$id]="idManager.asObservable()"
              [mappingComponentType]="'content'"
              class="custom-converter"
            ></app-converter-view>
          </mat-card>

          <!-- <mat-card class="mt-5 myShadow">
            <app-annotation
              *ngIf="this.document"
              [document]="this.document"
            ></app-annotation>
          </mat-card> -->

          <!-- {{ this.document._id | json }} -->
        </div>
      </div>
      </ng-container>

      <div class="col-md-1"></div>
    </div>
  </div>
  <ng-container *ngIf="document?._source">
  <div class="side-mini">
    <div class="position-fixed icon-fixed">
      <button mat-icon-button (click)="toggleBookmark($event, this.document)">
        <mat-icon
          [ngClass]="{
            'text-secondary-500': isBookmarked(this.document),
            'text-primary-500': !isBookmarked(this.document)
          }"
          >{{
            isBookmarked(this.document) ? "bookmark_added" : "bookmark_add"
          }}</mat-icon
        >
      </button>

      <button
        mat-icon-button
        color="primary"
        matBadge="merklisteCount"
        matBadgeOverlap="true"
        (click)="openDialog()"
        matTooltip="Merkliste"
      >
        <mat-icon>bookmarks</mat-icon>
      </button>

      <button
        mat-icon-button
        disabled
        color="primary"
        aria-label="Example icon button with a home icon"
        matTooltip="Drucken"
      >
        <mat-icon>print</mat-icon>
      </button>

      <button
        mat-icon-button
        disabled
        color="primary"
        aria-label="Example icon button with a menu icon"
        matTooltip="Hilfe"
      >
        <mat-icon>help</mat-icon>
      </button>
    </div>
  </div>
  </ng-container>
</div>
