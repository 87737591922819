import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { SearchFormComponent } from './search/search-form/search-form.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { FullTextSearchComponent } from './search/search-form/full-text-search/full-text-search.component';
import { FieldSpecificSearchComponent } from './search/search-form/specific-field-search/specific-field-search.component';
import { AggregationsComponent } from './search/search-form/aggregations/aggregations.component';
import { DateRangeComponent } from './search/search-form/date-range/date-range.component';
import { PreFilterComponent } from './search/search-form/pre-filter/pre-filter.component';
import { ChipListComponent } from './search/search-form/chip-list/chip-list.component';
//Angular Material Components
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ListItemComponent } from './search/list-item/list-item.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
// import { ConverterModule } from './document-view/converter-submodule/converter.module';
import { AnnotationComponent } from './document-view/annotation/annotation.component';
import { BookmarkDialogComponent } from './dialogs/bookmark-dialog/bookmark-dialog.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ConverterModule } from './document-view/converter-submodule/converter.module';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    SearchFormComponent,
    DocumentViewComponent,
    AnnotationComponent,
    ListItemComponent,
    HeaderComponent,
    FooterComponent,
    FullTextSearchComponent,
    FieldSpecificSearchComponent,
    AggregationsComponent,
    DateRangeComponent,
    PreFilterComponent,
    ChipListComponent,
    BookmarkDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    ConverterModule,
  ],
  exports: [
    BookmarkDialogComponent
    // andere Komponenten-Exporte
  ],
  providers: [
    // { provide: APP_BASE_HREF, useValue: '/wp-content/themes/owntheme2/datenbank/' }
  
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
