import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ConverterService } from '../services/converter.service';
import { UntypedFormControl } from '@angular/forms';
import { Wrapper } from '../wrapper';
import { SubcontentService } from '../services/subcontent.service';
import { MappingElement } from '../types/MappingTypes';

@Component({
  selector: 'app-element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElementComponent implements OnInit, AfterViewInit {

  @Input() representedMappingElement! : MappingElement;
  @Input() id! : number;
  @Input() exampledoc! : UntypedFormControl;
  @Input() wrapper! : Wrapper;
  @Input() urlAllDoctyps! : string;
  @Input() isLast! : boolean;
  @Input() mTitles! : string[] | null;
  @Input() kTitles! : string[] | null;
  
  @ViewChild('childrenContainer', {read : ViewContainerRef}) childrenContainer! : ViewContainerRef;
  @ViewChild('content') content! : ElementRef<HTMLDivElement>;
  @ViewChild('subcontent') subcontent! : ElementRef<HTMLDivElement>;
  @ViewChild('pre') prefix! : ElementRef<HTMLSpanElement>;
  @ViewChild('post') postfix! : ElementRef<HTMLSpanElement>;
  @ViewChild('label') label! : ElementRef<HTMLSpanElement>;

  constructor(
    private converterService : ConverterService,
    private componentRef : ElementRef, // reference to this components html element
    private subcontentService : SubcontentService
  ) {
  }

  ngOnInit(): void {
    //this.onReady().then(()=>{console.log('YAY');});
  }

  async onReady(): Promise<void>{
    while(!this.childrenContainer)
      await new Promise(f => setTimeout(f, 1000));
    return new Promise((resolve:any)=>{
      resolve();
    });
  }

  ngAfterViewInit(): void {
    this.init();
  }

  public init(){
    const labelWrapDiv = this.label.nativeElement;
    const contentDiv = this.content.nativeElement;
    labelWrapDiv.id = 'elcontent'+this.id;
    if(this.representedMappingElement.elabel){
      this.representedMappingElement.elabel.forEach((label : any) => {
        // TODO language handling
        let labelstring = label.labelstring;
        if(!labelstring){
          labelstring = '';
        }
        const labelSpan = this.label.nativeElement;
        labelSpan.innerHTML = labelstring;
        this.converterService.handleElabelClass(label.eclass, labelSpan, contentDiv);
      });
    } else {
      if(!this.representedMappingElement?.eclass?.includes('heading')){
        contentDiv.classList.add('col-md-12');
      }
    }
    contentDiv.classList.add('content-wrap','pb-1');
    this.prePost();
    const subcontentDiv = this.subcontent.nativeElement;
    const econtents = this.representedMappingElement.econtent;
        
    const wrapper = new Wrapper(
      this.componentRef.nativeElement, this.wrapper, labelWrapDiv, contentDiv, subcontentDiv, econtents.length, this.representedMappingElement);   
    
    this.converterService.handleEclass(
      this.representedMappingElement.eclass,
      this.representedMappingElement.bootstrap,
      this.componentRef.nativeElement,
      subcontentDiv,
      this.wrapper);
    
    econtents.forEach((subcontent: any, subcontentIndex: number)=>{
      let exampleElement;
      if(subcontent.etype!= 'linkgroup'){
        if(subcontent.epart){
          exampleElement = this.converterService.get(this.exampledoc,subcontent.epart);
        }
        else{
          // falls Gruppe oder ähnliches ohne epart gegeben ist, fahre mit aktuellem epart fort
          exampleElement = this.exampledoc;
        }
      }
      else{
        exampleElement = this.exampledoc;
      }
      this.subcontentService.createSubComponent(
        this.childrenContainer, {
          representedMappingElement : subcontent,
          id : this.id+' '+subcontentIndex,
          exampleElement : exampleElement,
          wrapper : wrapper,
          isLastBlockElement : false,
          kTitles : this.kTitles,
          mTitles : this.mTitles,
          childCount : econtents?.length
        });
    });
  }


  prePost(){
    const prefixSpan = this.prefix.nativeElement;
    const prefix = this.representedMappingElement.epartpre;
    if(prefix){
      if(prefix.epartpre){
        prefixSpan.innerHTML = prefix.epartpre;
        this.converterService.handleEpartPrePostClass(prefix.eclass, prefixSpan);
      }
    }
    const postfixSpan = this.postfix.nativeElement;
    const postfix = this.representedMappingElement.epartpost;
    if(postfix){
      if(postfix.epartpost){
        if(this.isLast && postfix.eclass !== undefined && postfix.eclass.includes('without-last')){
          // leave out epartpost
        } else{
          postfixSpan.innerHTML = postfix.epartpost;
        }
        this.converterService.handleEpartPrePostClass(postfix.eclass, postfixSpan);
      }
    }
  }

}
