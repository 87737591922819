import { Injectable } from '@angular/core';
import { ConverterService } from './converter.service';
import { HttpService } from './http.service';
import { MappingSubcontent, MappingSubcontentLinkSubcontent } from '../types/MappingTypes';

export interface MappingElementWithFUD{
  documentValue: string,
  mappingValue?: MappingSubcontent
}

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  constructor(
      private http : HttpService,
      private converterService : ConverterService
  ) { }

  getTarget(
    mappingJSON: MappingSubcontent,
    fudJSON: any
  ) : MappingElementWithFUD | null{
    return this.recGetFromFUD(mappingJSON, fudJSON);
  }

  getContentNoHttp(
    mappingJSON : MappingSubcontentLinkSubcontent,
    fudJSON : any
  ) : MappingElementWithFUD | null{
    return this.recGetFromFUD(mappingJSON, fudJSON);
  }

  // gets the content from another document using an http request
  async getContentHttp(
    mappingJSON: MappingSubcontentLinkSubcontent,
    fudJSON: any,
    fudIndex: string,
    target: string | null // target id if another fudIndex is given
  ) : Promise<MappingElementWithFUD | null>{
    let content;
    // is of same fud doctyp? then fudIndex would be undefined or empty
    if(fudIndex?.length > 1){
      if(!target){
        return null;
      }
      // in this case we start from the first layer of the fud
      // mappingJSON contains in epart / subcontent and then epart the fields we want to take
      // fudJSON needs to be overridden
      return new Promise((resolve) => {
        this.http.getDocument(fudIndex, target).then((document : any)=>{
          fudJSON = document._source;
          const content = this.recGetFromFUD(mappingJSON, fudJSON);
          resolve(content);
        });
      });
    } else {
      content = this.recGetFromFUD(mappingJSON, fudJSON);
      return content;
    }
  }

  // gets the target id of a q field
  // no http requests needed
  private recGetFromFUD(mapping: MappingSubcontent, fud: any) : MappingElementWithFUD | null{
    if(!mapping){
      return {documentValue: fud};
    }
    let subcontentIfExists;
    fud = this.converterService.get(fud, mapping.epart);    
    if(!fud){
      // field does not exist in this example document
      return null;
    }
    if((subcontentIfExists = mapping.subcontent) != undefined && !mapping.usefunction){ // Rekursion geht weiter
      if(mapping.typ == 'group' || mapping.etype == 'block'){
        mapping = subcontentIfExists[0] as MappingSubcontent;
        for(const v of Object.values(fud)){
          return this.recGetFromFUD(mapping,v as any);
        }
        // no example contents -> field can not be found
        return null;
      }
      else{ // etype child
        mapping = subcontentIfExists[0] as MappingSubcontent;
        return this.recGetFromFUD(mapping,fud);
      }
    }
    else{ // etype weder child noch block oder group, also id ablesbar, Rekursionsanker
      return {mappingValue: mapping, documentValue: fud};
    }
  }
}