<div [ngClass]="{ active: queryStateService.isAggregationFilterActive() }">
  <mat-accordion *ngIf="aggregations" multi="true" closed>
    <div
      class="mt-4 d-flex justify-between align-items-center headline-searchForm text-primary-500 mb-0 bg-white border-b-2 border-primary-500"
    >
      <div class="d-flex justify-content-start align-items-center">
        <mat-icon class="mr-2 text-primary-500">checklist</mat-icon>
        <p>Facetten</p>
      </div>

      <mat-radio-group
        [(ngModel)]="sortOption"
        aria-label="Select sorting option"
        class="d-flex"
      >
        <mat-radio-button value="alphabetical" class="mr-2"
          >Alphanumerisch</mat-radio-button
        >
        <mat-radio-button value="count">Anzahl</mat-radio-button>
      </mat-radio-group>
      <span class="line"></span>
    </div>

    <div class="d-flex justify-content-end mb-2"></div>

    <ng-container *ngFor="let aggKey of getAggregationKeys()">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="mr-3 blue">{{
              getAggConfig(aggKey).facetIcon
            }}</mat-icon>
            {{ getAggConfig(aggKey).facetLabel }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngFor="let facet of getSortedFacets(aggKey)">
          <ng-container *ngIf="facet.key.length > 0">
            <mat-checkbox
              [checked]="isTermSelected(aggKey, facet.key)"
              (change)="onCheckboxChange($event, facet.key, aggKey)"
            >
              {{ facet.key }} ({{ facet.doc_count }})
            </mat-checkbox>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>