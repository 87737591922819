import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { QueryService } from "../../services/query.service";
import { ApiService } from "../../services/api.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { SEARCH_CONFIG } from "src/search-config";
import { Router } from "@angular/router";
import { QueryStateService } from "src/app/services/query-state.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BookmarkService } from "src/app/services/bookmark.service";
import { BookmarkItem } from "src/app/services/bookmark.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  stagger,
} from "@angular/animations";

@Component({
  selector: "app-list-item",
  templateUrl: "./list-item.component.html",
  styleUrls: ["./list-item.component.scss"],
  animations: [
    trigger("listFadeIn", [
      transition("* <=> *", [
        // This transition applies to any change in the list
        query(
          ":enter",
          [
            style({ opacity: 0 }),
            stagger("100ms", [
              // Adjust the delay here as needed
              animate("0.5s", style({ opacity: 1 })), // Adjust animation duration here
            ]),
          ],
          { optional: true }
        ), // Marking the query as optional: true is useful for server-side rendering
      ]),
    ]),
  ],
})
export class ListItemComponent {
  searchResults: any;
  esQuery: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  totalDocuments = 0;
  pageSize = 25;
  pageIndex = 0;
  snr: any;

  constructor(
    private queryService: QueryService,
    private router: Router,
    public queryStateService: QueryStateService,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private bookmarkService: BookmarkService
  ) {}

  ngOnInit() {
    this.queryService.response$.subscribe((data) => {
      this.searchResults = data;
      this.totalDocuments = data?.hits?.total?.value; // Adjust according to your API response structure
      // Process data as needed
      this.searchResults?.hits?.hits.forEach((document: any) => {
        // CUSTOM CHANGES HERE!!!!!!
        // if (document["_source"]["2_ar_kplink"]) {
        //   this.apiService
        //     .getDocument(document["_source"]["2_ar_kplink"])
        //     .subscribe((res: any) => {
        //       if (res.hits.hits[0]["_source"]["4_pr_snr"]) {
        //         document["_source"]["4_pr_snr"] =
        //           res.hits.hits[0]["_source"]["4_pr_snr"];
        //       }
        //     });
        // }
      });
    });

    this.queryService.getQuery().subscribe((query) => {
      this.esQuery = query;
    });
  }

  onPageChange(event: PageEvent) {
    this.queryStateService.updatePageSize(event.pageSize);
    this.queryStateService.updatePageIndex(event.pageIndex);
  }
  // Funktion, um die Konfiguration basierend auf aggKey zu finden
  getDoctypConfig(key: string) {
    const config = SEARCH_CONFIG.docTyps.find(
      (docTyp) => docTyp.docValue === key
    );
    return config || { docLabel: key, docIcon: "" };
  }

  toDocument(id: any) {
    this.router.navigate(["/document", id]);
  }

  toggleBookmark(event: Event, document: any): void {
    event.stopPropagation(); // Prevent the event from bubbling up
    console.log(document);
    // Create the bookmark item
    const bookmarkItem: BookmarkItem = {
      id: document._id,
      title: document["_source"]["5_dcterms_title"],
    };

    // Toggle the bookmark in the service
    this.bookmarkService.toggleItem(bookmarkItem.id, bookmarkItem.title);

    // Show feedback to the user
    const message = this.bookmarkService.isInMerkliste(bookmarkItem.id)
      ? "Zur Merkliste hinzugefügt"
      : "Von Merkliste entfernt";
    this.snackBar.open(message, "", {
      duration: 2000,
    });
  }

  isBookmarked(document: any): boolean {
    return this.bookmarkService.isInMerkliste(document._id);
  }
}
