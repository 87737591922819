<span #label class="label">
</span>
<span #content [ngClass]="representedMappingElement?.eclass?.includes('heading') ? '' : 'col'">
  <span #pre>
  </span>
  <span #subcontent>
    <ng-container #childrenContainer></ng-container>
  </span>
  <span #post>
  </span>
</span>