import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "./api.service";
import { SEARCH_CONFIG } from "src/search-config";
import { QueryStateService } from "./query-state.service";
import { QueryState } from "../models/query-state.model";

@Injectable({
  providedIn: "root",
})
export class QueryService {
  private querySubject = new BehaviorSubject<any>({ query: { match_all: {} } });
  private responseSubject = new BehaviorSubject<any>(null);
  private idsSubject = new BehaviorSubject<string[]>([]); // Observable für IDs

  response$ = this.responseSubject.asObservable(); // Expose as Observable
  ids$ = this.idsSubject.asObservable(); // Observable der IDs

  constructor(
    private queryStateService: QueryStateService,
    private apiService: ApiService
  ) {
    this.queryStateService.queryStateObservable.subscribe((queryState) => {
      console.log("New query state received in QueryService:", queryState);
      const esQuery = this.buildQuery(queryState);
      this.querySubject.next(esQuery);
      this.executeSearch(esQuery);
    });
  }

  public executeSearch(query: any) {
    this.queryStateService.setLoading(true);
    console.log(query)
    this.apiService.search(query).subscribe(
      (response) => {
        console.log("RESPONSE = ", response);
        this.responseSubject.next(response);
        const ids = response.hits.hits.map((hit: any) => hit._id); // Extrahiere die IDs
        console.log(ids);
        this.idsSubject.next(ids); // Aktualisiere das Observable der IDs
        this.queryStateService.setLoading(false);
      },
      (error) => {
        console.error("Search error:", error);
        this.queryStateService.setLoading(false); // Setze isLoading auf false auch im Fehlerfall
      }
    );
  }

  getQuery() {
    return this.querySubject.asObservable();
  }

  private buildQuery(queryState: QueryState): any {
    // Fix: Specify a more specific type for the return value

    const esQuery: any = {
      query: {
        bool: {
          must: [],
          should: [],
          filter: [],
          must_not: [],
        },
      },
      aggs: {},
      from: queryState.from,
      size: queryState.size,
    };

    // Initialize with a match_all query if there are no specific criteria
    if (
      !queryState.fullText &&
      queryState.specificFields.fields.length === 0 &&
      !queryState.dateRange
    ) {
      esQuery.query.bool.must.push({ match_all: {} });
    }

    // Handle Full Text Search
    if (queryState.fullText?.trim() !== "") {
      esQuery.query.bool.must.push({
        query_string: {
          query: queryState.fullText,
          fields: ["*"],
        },
      });
    }

    // Add sort to the query if a sort option is selected
    if (
      queryState.sortOption &&
      queryState.sortOption.sortField &&
      queryState.sortOption.sortField !== "_score"
    ) {
      esQuery.sort = [
        {
          [queryState.sortOption.sortField]: {
            order: queryState.sortOption.sortMethod,
          },
        },
      ];
    }

    // Specific Fields based on AND/OR logic
    if (queryState.specificFields.relationship === "AND") {
      queryState.specificFields.fields.forEach((field) => {
        esQuery.query.bool.must.push({ match: { [field.field]: field.value } });
      });
    } else {
      queryState.specificFields.fields.forEach((field) => {
        esQuery.query.bool.should.push({
          match: { [field.field]: field.value },
        });
      });
      if (queryState.specificFields.fields.length > 0) {
        esQuery.query.bool.minimum_should_match = 1;
      }
    }

    // Hinzufügen ausgeschalteter Doctyp-Filter im `must_not`-Teil
    Object.entries(queryState.docTypeFilters).forEach(([docType, isActive]) => {
      if (!isActive) {
        esQuery.query.bool.must_not.push({
          match: { facet_doctyp: docType },
        });
      }
    });

    // Date Range Filter
    if (queryState.dateRange && queryState.dateRange.isActive) {
      esQuery.query.bool.filter.push({
        range: {
          [SEARCH_CONFIG.range.gte]: {
            gte: queryState.dateRange.start,
            lte: queryState.dateRange.end,
          },
        },
      });
    }
    // Integriere Aggregationen aus dem QueryState
    Object.entries(queryState.aggregations).forEach(([aggKey, facetKeys]) => {
      facetKeys.forEach((facetKey) => {
        esQuery.query.bool.filter.push({
          term: { [aggKey]: facetKey },
        });
      });
    });

    // Aggregations Auflistung
    SEARCH_CONFIG.aggs.forEach((agg) => {
      esQuery.aggs[agg.facetField] = {
        terms: { field: agg.facetField, size: 999 },
      };
    });

    //
    console.log(esQuery);
    return esQuery;
  }
}
