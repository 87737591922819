import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDrawer } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { BookmarkService } from '../services/bookmark.service';
import { BookmarkDialogComponent } from '../dialogs/bookmark-dialog/bookmark-dialog.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
  @ViewChild('drawer') drawer!: MatDrawer;

  constructor(
    public dialog: MatDialog, 
    public bookmarkService: BookmarkService
  ) {

  }

  toggleDrawer() {
    this.drawer.toggle();
  }

  openDialog(): void {
    this.dialog.open(BookmarkDialogComponent, {
      minWidth: 600,
      minHeight: 400,
      data: this.bookmarkService.getMerklisteItems(), // Angenommen, diese Methode gibt die Merkliste-Items zurück
    });
  }

  get merklisteCount(): number {
    return this.bookmarkService.getMerklisteCount(); // Angenommen, diese Methode gibt die Anzahl der Merkliste-Items zurück
  }
}
