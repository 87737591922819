import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { QueryStateService } from 'src/app/services/query-state.service';
import { SEARCH_CONFIG } from 'src/search-config';

@Component({
  selector: 'app-specific-field-search',
  templateUrl: './specific-field-search.component.html',
  styleUrls: ['./specific-field-search.component.scss']
})
export class FieldSpecificSearchComponent implements OnInit, OnDestroy {
  fieldForm: FormGroup;
  selectOptions: { fieldName: string; uiTitle: string; facet: string; }[];
  autocompleteData: { [key: string]: string[] } = {};
  private subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    public queryStateService: QueryStateService,
    private apiService: ApiService
  ) {
    this.fieldForm = this.fb.group({
      specificFieldGroups: this.fb.array([]),
      fieldRelationship: [{value: 'AND', disabled: true}], // Initially disabled
    });
    this.selectOptions = SEARCH_CONFIG.specificFields;
  }

  ngOnInit() {
    this.initializeForm();
    this.fetchFacetData();
    this.subscriptions.add(
      this.queryStateService.queryStateObservable.subscribe(() => {
        this.populateFormFromState();
      })
    );

  }

  private initializeForm(): void {
    this.addSpecificFieldGroup(); // Always initialize with one group
    this.fieldForm.get('fieldRelationship')?.setValue('AND');
  }

  get specificFieldGroups(): FormArray {
    return this.fieldForm.get('specificFieldGroups') as FormArray;
  }

  populateFormFromState(): void {
    // Fetch the current query state
    const queryState = this.queryStateService.getQueryState();
    // Clear existing form groups
    const specificFieldGroups = this.fieldForm.get('specificFieldGroups') as FormArray;
    specificFieldGroups.clear();
    this.fieldForm.get('fieldRelationship')?.setValue(queryState.specificFields.relationship || 'AND');

    // Check if there are specific fields stored in the state
    if (queryState.specificFields && queryState.specificFields.fields.length > 0) {
      // Populate the form with the specific fields from the state
      queryState.specificFields.fields.forEach(field => {
        specificFieldGroups.push(this.fb.group({
          selectField: [field.field, Validators.required],
          autoCompleteControl: [{value: field.value, disabled: false}, Validators.required],
        }));
      });
  
      // Set the field relationship (AND/OR) based on the state
      this.fieldForm.get('fieldRelationship')?.setValue(queryState.specificFields.relationship);
      this.fieldForm.get('fieldRelationship')?.enable({emitEvent: false});
    } else {
      // If no specific fields are stored or the state is reset, reinitialize with default values
      this.addSpecificFieldGroup();
      this.fieldForm.get('fieldRelationship')?.setValue('AND', {emitEvent: false});
      // Optionally, disable the fieldRelationship control if no fields are selected
      // this.fieldForm.get('fieldRelationship')?.disable({emitEvent: false});
    }

  }

  addSpecificFieldGroup(): void {
    const group = this.fb.group({
      selectField: ['', Validators.required],
      autoCompleteControl: [{value: '', disabled: true}, Validators.required], // Initial deaktiviert
    });
  
    this.specificFieldGroups.push(group);
    this.checkFormGroupsValidity();
  }
  
  onFieldSelect(index: number): void {
    const fieldGroup = this.specificFieldGroups.at(index) as FormGroup;
    const autoCompleteControl = fieldGroup.get('autoCompleteControl');
    autoCompleteControl?.enable(); // Aktiviere das Autocomplete-Feld
  }

  removeSpecificFieldGroup(index: number): void {
    this.specificFieldGroups.removeAt(index);
    this.checkFormGroupsValidity(); // Check validity whenever a group is removed
    this.updateQueryState();
  }

  checkFormGroupsValidity(): void {
    // Enable the AND/OR selector if any group has both fields filled
    const isValid = this.specificFieldGroups.controls.some(group => group.valid);
    this.fieldForm.get('fieldRelationship')?.enable({emitEvent: false});
    if (!isValid) {
      this.fieldForm.get('fieldRelationship')?.disable({emitEvent: false});
    }
  }



    // Call this method whenever you need to update the state based on the form values
    updateQueryState(): void {
      const specificFields = this.fieldForm.value.specificFieldGroups
          .filter((group: any) => group.selectField.trim() !== '' && group.autoCompleteControl.trim() !== '')
          .map((group: any) => ({
              field: group.selectField,
              value: group.autoCompleteControl
          }));
    
      const fieldRelationship = this.fieldForm.get('fieldRelationship')?.value;
    
      if (specificFields.length > 0) {
        this.queryStateService.setSpecificFields(specificFields, fieldRelationship);
      } else {
        console.log('No specific fields available, resetting QueryState.');
        this.queryStateService.resetSpecificFields();
      }
    }
    
  
    
    

  rebuildFormWithSpecificFields(specificFields: any) {
    // Clear existing form groups
    const specificFieldGroups = this.fieldForm?.get('specificFieldGroups') as FormArray;
    specificFieldGroups.clear();
  
    // Add form groups based on state
    specificFields.fields.forEach((field: any) => {
      specificFieldGroups.push(this.fb.group({
        selectField: field.field,
        autoCompleteControl: field.value
      }));
    });
  
  }


  onOptionSelected(event: MatAutocompleteSelectedEvent, index: number): void {
    const selectedOption = event.option.value;
    const specificFieldGroup = this.specificFieldGroups.at(index);

    specificFieldGroup.get('autoCompleteControl')?.setValue(selectedOption);
  
    // Stelle sicher, dass dies den fieldRelationship-Zustand nicht beeinflusst
    this.updateQueryState();
}

  


// Assume autocompleteData is populated like this for simplicity
// autocompleteData: { [key: string]: string[] } = { "fieldName1": ["Option1", "Option2", ...] };

getFilteredOptions(index: number): string[] {
  const selectFieldControl = this.specificFieldGroups.at(index).get('selectField');
  const autoCompleteControl = this.specificFieldGroups.at(index).get('autoCompleteControl');
  if (!selectFieldControl || !autoCompleteControl) return [];

  const options = this.autocompleteData[selectFieldControl.value] || [];
  const filterValue = autoCompleteControl.value.toLowerCase();

  return options.filter(option => option.toLowerCase().includes(filterValue));
}




fetchFacetData(): void {
  this.apiService.getFacetData().subscribe((data: any) => {
    console.log(data.aggregations);
    Object.keys(data.aggregations).forEach(facetKey => {
      const buckets = data.aggregations[facetKey].buckets;
      // Filter out buckets where the key is an empty string
      this.autocompleteData[facetKey] = buckets
        .filter((bucket: any) => bucket.key.trim() !== '')
        .map((bucket: any) => bucket.key);
    });
  });
}


  
  // Called in ngOnInit to initialize selectOptions
  initializeSelectOptions(): void {
    // Assuming SEARCH_CONFIG.specificFields might contain entries with empty strings
    this.selectOptions = SEARCH_CONFIG.specificFields
      .filter(option => option.fieldName.trim() !== '' && option.uiTitle.trim() !== '');
  }

resetForm() {
  // Clear the form array
  const specificFieldGroups = this.fieldForm.get('specificFieldGroups') as FormArray;
  specificFieldGroups.clear();

  // Reinitialize the form with default values
  this.addSpecificFieldGroup();
  this.fieldForm.get('fieldRelationship')?.setValue('AND', {emitEvent: false});
  this.fieldForm.get('fieldRelationship')?.disable({emitEvent: false}); // Optionally disable if needed
}




  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }




}
