<mat-card
  class="mt-4"
  [ngClass]="{ active: queryStateService.isFieldSpecificSearchActive() }"
>
  <div
    class="d-flex justify-content-start align-items-center headline-searchForm text-primary-500 mb-0 mb-3 border-b-2 border-primary-500"
  >
    <mat-icon class="mr-2 text-primary-500">manage_search</mat-icon>
    <p>Field specific search</p>
  </div>

  <mat-card-content>
    <form [formGroup]="fieldForm">
      <div formArrayName="specificFieldGroups">
        <div
          *ngFor="let group of specificFieldGroups.controls; let i = index"
          [formGroupName]="i"
          class="mb-2 row"
        >
          <!-- Field Selector -->
          <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Field</mat-label>
            <mat-select
              formControlName="selectField"
              (selectionChange)="onFieldSelect(i)"
            >
              <mat-option
                *ngFor="let option of selectOptions"
                [value]="option.fieldName"
              >
                {{ option.uiTitle }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Autocomplete Input -->
          <mat-form-field appearance="outline" class="col">
            <input
              matInput
              placeholder="Value"
              [matAutocomplete]="auto"
              formControlName="autoCompleteControl"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onOptionSelected($event, i)"
            >
              <mat-option
                *ngFor="let option of getFilteredOptions(i)"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <!-- Remove Field Group Button -->
          <button
            class="mt-1 mr-2"
            mat-icon-button
            color="warn"
            (click)="removeSpecificFieldGroup(i)"
            aria-label="Remove field group"
          >
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </div>
      </div>

      <!-- Add Field Group Button -->
      <button mat-stroked-button (click)="addSpecificFieldGroup()" class="my-2">
        New group
      </button>

      <!-- Field Relationship Selector -->
      <div class="my-3">
        <mat-radio-group
          formControlName="fieldRelationship"
          aria-label="Field Relationship"
          (change)="updateQueryState()"
        >
          <mat-radio-button value="AND">AND</mat-radio-button>
          <mat-radio-button value="OR">OR</mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
  </mat-card-content>
</mat-card>
