import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentViewComponent } from './document-view/document-view.component';
import { SearchComponent } from './search/search.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  { path: '', component: SearchComponent , pathMatch: 'full'},
  { path: 'document/:id', component: DocumentViewComponent , runGuardsAndResolvers: 'always'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy } // This line is optional as `useHash: true` already takes care of using the HashLocationStrategy
  ],
})
export class AppRoutingModule { }



