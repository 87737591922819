import { Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { SubcontentComponent } from '../base/subcontent.component';
import { LinkService } from '../../services/link.service';
import { ConverterService } from '../../services/converter.service';
import { DateService } from '../../services/date.service';
import { SubcontentService } from '../../services/subcontent.service';
import { MappingSubcontentJson, UsefunctionSubcontent } from '../../types/MappingTypes';

@Component({
  selector: 'app-subcontent--json',
  templateUrl: '../base/subcontent.component.html',
  styleUrls: ['./json.subcontent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class JsonSubcontentComponent extends SubcontentComponent{

  @Input() override representedMappingElement! : MappingSubcontentJson;

  constructor(
    protected override linkService : LinkService,
    protected override converterService : ConverterService,
    protected override dateService : DateService,
    protected override componentRef : ElementRef,
    protected override subcontentService : SubcontentService
  ){
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService
    );
  }

  override init() {
    switch(this.representedMappingElement.typ){
    default:
      switch(this.representedMappingElement.etype){
      case 'json':
        this.handleJSON();
        break;
      default:
        console.error('Created JsonSubcontentComponent but is not of etype json!');
        // in case something unexpected happens, let the super class handle it
        super.init();
      }
    }
  }

  handleJSON() {
    const wrapper = this.handleBoxing(0);
    const content = this.exampleElement;
    if(!content)
      return;
    let str : string = '';
    if(this.exampleElement instanceof Object){
      console.log(this.exampleElement);
      
    }
    if(this.exampleElement != undefined){
      str += content;
    }
    else{
      str += '';
    }
    if(this.representedMappingElement.usefunction === 'dateformat' && this.representedMappingElement.subcontent){
      const usefunctionSubcontent = this.representedMappingElement.subcontent[0] as UsefunctionSubcontent;
      if(usefunctionSubcontent){
        str = this.dateService.formatDate(str,
          usefunctionSubcontent.epart,
          usefunctionSubcontent.elang || 'de-DE');
      }
    }
    this.subcontent.nativeElement.innerHTML = str;
    this.subcontent.nativeElement.classList.add('json-span');
    this.styleSpan(this.subcontent.nativeElement, wrapper);
  }

}