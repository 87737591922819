import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { SubcontentComponentCreateRequest, LinkSubcontentComponentCreateRequest } from '../types/SubcontentComponentCreateRequest';
import { SubcontentComponent } from '../subcontent/base/subcontent.component';
import { LinkItemSubcontentComponent } from '../subcontent/extends/link-item.subcontent.component';

@Injectable({
  providedIn: 'root'
})
export class SubcontentService{
  constructor(
  ){
  }
  
  public createSubComponent(
    viewContainerRef : ViewContainerRef,
    request : SubcontentComponentCreateRequest
  ) : Promise<ComponentRef<SubcontentComponent>> {
    return new Promise((resolve, reject)=>{
      let componentRef;
            
      switch(request.representedMappingElement.typ){
      case 'file':
      case 'virtual_file':
      case 'all_files':
      case 'all_images':
        import('../subcontent/extends/file.subcontent.component').then((module) => {
          componentRef = viewContainerRef.createComponent(module.FileSubcontentComponent);
          this.fillFromRequest(componentRef, request);
          resolve(componentRef);
        }).catch(error => {
          console.error('Error loading FileSubcontentComponent', error);
          reject(error);
        });
        break;
      case 'group':
        import('../subcontent/extends/group.subcontent.component').then((module) => {
          componentRef = viewContainerRef.createComponent(module.GroupSubcontentComponent);
          this.fillFromRequest(componentRef, request);
          resolve(componentRef);
        }).catch(error => {
          console.error('Error loading GroupSubcontentComponent', error);
          reject(error);
        });
        break;
      case 'string':
        import('../subcontent/extends/string.subcontent.component').then((module) => {
          componentRef = viewContainerRef.createComponent(module.StringSubcontentComponent);
          this.fillFromRequest(componentRef, request);
          resolve(componentRef);
        }).catch(error => {
          console.error('Error loading StringSubcontentComponent', error);
          reject(error);
        });
        break;
      case 'link_group':
      case 'link_string':
      case 'link_fud':
        console.error('To create a LinkSubcontentComponent use function createLinkSubComponent and not createSubComponent');
        break;
      default:
        switch(request.representedMappingElement.etype){  
        case 'json':
          import('../subcontent/extends/json.subcontent.component').then((module) => {
            componentRef = viewContainerRef.createComponent(module.JsonSubcontentComponent);
            this.fillFromRequest(componentRef, request);
            resolve(componentRef);
          }).catch(error => {
            console.error('Error loading JsonSubcontentComponent', error);
            reject(error);
          });
          break;
        case 'block':
          import('../subcontent/extends/block.subcontent.component').then((module) => {
            componentRef = viewContainerRef.createComponent(module.BlockSubcontentComponent);
            this.fillFromRequest(componentRef, request);
            resolve(componentRef);
          }).catch(error => {
            console.error('Error loading BlockSubcontentComponent', error);
            reject(error);
          });
          break;
        case 'child':
          import('../subcontent/extends/child.subcontent.component').then((module) => {
            componentRef = viewContainerRef.createComponent(module.ChildSubcontentComponent);
            this.fillFromRequest(componentRef, request);
            resolve(componentRef);
          }).catch(error => {
            console.error('Error loading ChildSubcontentComponent', error);
            reject(error);
          });
          break;
        case 'htmltext':
          import('../subcontent/extends/htmltext.subcontent.component').then((module) => {
            componentRef = viewContainerRef.createComponent(module.HtmltextSubcontentComponent);
            this.fillFromRequest(componentRef, request);
            resolve(componentRef);
          }).catch(error => {
            console.error('Error loading HtmltextSubcontentComponent', error);
            reject(error);
          });
          break;
        case 'linkcontent':
          console.error('To create a LinkSubcontentComponent use function createLinkSubComponent and not createSubComponent');
          break;
        case 'linkgroup':
          import('../subcontent/extends/link.subcontent.component').then((module) => {
            componentRef = viewContainerRef.createComponent(module.LinkSubcontentComponent);
            this.fillFromRequest(componentRef, request);
            resolve(componentRef);
          }).catch(error => {
            console.error('Error loading LinkgroupSubcontentComponent', error);
            reject(error);
          });
          break;
        default:
          componentRef = viewContainerRef.createComponent(SubcontentComponent);
          this.fillFromRequest(componentRef, request);
          break;
        }
        break;
      }
    });
  }

  public async createLinkSubComponent(
    viewContainerRef : ViewContainerRef,
    request : LinkSubcontentComponentCreateRequest
  ) : Promise<ComponentRef<LinkItemSubcontentComponent>> {
    return new Promise((resolve, reject)=>{
      import('../subcontent/extends/link-item.subcontent.component').then((module) => {
        const componentRef = viewContainerRef.createComponent(module.LinkItemSubcontentComponent);
        this.fillFromLinkRequest(componentRef, request);
        resolve(componentRef);
      }).catch(error => {
        console.error('Error loading LinkSubcontentComponent', error);
        reject(error);
      });
    });
  }

  public fillFromRequest(componentRef : ComponentRef<SubcontentComponent>, request : SubcontentComponentCreateRequest){
    componentRef.instance.representedMappingElement = request.representedMappingElement;
    componentRef.instance.id = request.id;
    componentRef.instance.exampleElement = request.exampleElement;
    componentRef.instance.wrapper = request.wrapper;
    componentRef.instance.childCount = request.childCount;
    componentRef.instance.isLastBlockElement = request.isLastBlockElement;
    componentRef.instance.kTitles = request.kTitles;
    componentRef.instance.mTitles = request.mTitles;
  }

  public fillFromLinkRequest(componentRef : ComponentRef<LinkItemSubcontentComponent>, request : LinkSubcontentComponentCreateRequest){
    this.fillFromRequest(componentRef, request);
    componentRef.instance.linkFromOtherDocument = request.linkFromOtherDocument;
    componentRef.instance.linkExtern = request.linkExtern;
    componentRef.instance.linkFudIndex = request.linkFudIndex;
    componentRef.instance.linkTarget = request.linkTarget;
  }
}