<mat-card
  class="mt-4"
  [ngClass]="{ active: queryStateService.isDocTypeFilterActive() }"
>
  <div
    class="d-flex justify-content-start align-items-center headline-searchForm text-primary-500 mb-0 mb-3 border-b-2 border-primary-500"
  >
    <mat-icon class="mr-2 text-primary-500">filter_alt</mat-icon>
    <p>Pre-filter</p>
  </div>

  <mat-card-content>
    <div class="d-flex flex-column">
      <mat-slide-toggle
        *ngFor="let docType of docTypes"
        [checked]="docTypeToggleStates[docType.docValue]"
        class="mb-2"
        (change)="onDocTypeToggleChange(docType.docValue, $event.checked)"
      >
        {{ docType.docLabel }}
      </mat-slide-toggle>
    </div>
  </mat-card-content>
</mat-card>
